import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1042.000000 1042.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1042.000000) scale(0.100000,-0.100000)">


<path d="M3822 7483 c-59 -64 -115 -161 -149 -258 -25 -73 -27 -93 -27 -220 0
-127 2 -147 27 -220 52 -150 164 -305 220 -305 33 0 67 32 67 63 0 13 -22 51
-50 87 -65 79 -96 142 -121 238 -24 96 -24 179 1 276 23 93 66 176 126 246 49
57 55 89 24 120 -34 34 -68 26 -118 -27z"/>
<path d="M4770 7510 c-31 -31 -24 -61 30 -130 65 -79 96 -142 121 -238 24 -95
24 -179 0 -274 -25 -96 -56 -159 -121 -238 -28 -36 -50 -74 -50 -87 0 -31 34
-63 67 -63 56 0 168 155 220 305 25 73 27 93 27 220 1 131 -1 145 -31 228 -36
103 -81 180 -144 248 -50 55 -85 63 -119 29z"/>
<path d="M3978 7338 c-51 -56 -94 -146 -113 -233 -18 -87 -18 -113 0 -200 19
-90 55 -161 111 -224 51 -56 81 -64 114 -31 30 30 25 65 -19 120 -70 89 -101
221 -75 326 11 44 51 119 84 157 35 40 39 78 10 107 -32 32 -67 25 -112 -22z"/>
<path d="M4620 7360 c-29 -29 -25 -66 14 -113 62 -77 79 -123 84 -222 5 -108
-16 -176 -79 -255 -44 -55 -49 -90 -19 -120 33 -33 63 -25 114 31 56 63 92
134 111 224 18 87 18 113 0 200 -18 85 -63 177 -114 233 -44 47 -79 54 -111
22z"/>
<path d="M4137 7198 c-96 -103 -95 -283 3 -388 33 -35 71 -39 100 -10 28 28
25 57 -10 109 -24 35 -30 55 -30 96 0 41 6 61 30 96 35 52 38 81 10 109 -30
30 -67 25 -103 -12z"/>
<path d="M4470 7210 c-28 -28 -25 -57 10 -109 24 -35 30 -55 30 -96 0 -41 -6
-61 -30 -96 -35 -52 -38 -81 -10 -109 29 -29 67 -25 100 10 98 105 100 284 3
388 -36 37 -73 42 -103 12z"/>
<path d="M4314 7078 c-24 -11 -44 -48 -44 -79 0 -30 53 -79 85 -79 32 0 85 49
85 79 0 68 -65 109 -126 79z"/>
<path d="M4300 6762 c-74 -40 -74 -41 -321 -1018 -249 -988 -253 -1003 -246
-1010 2 -2 35 14 72 35 262 153 559 194 831 116 90 -27 223 -85 292 -129 24
-15 46 -25 49 -22 7 7 9 0 -240 991 -150 594 -236 918 -253 953 -45 88 -116
121 -184 84z m222 -1055 c41 -20 82 -45 92 -56 19 -21 169 -620 164 -653 -3
-14 -14 -10 -67 22 -35 21 -103 51 -150 67 -75 24 -101 28 -206 28 -105 0
-131 -4 -206 -28 -47 -16 -115 -46 -150 -67 -53 -32 -64 -36 -67 -22 -3 21
146 644 156 652 102 75 175 101 279 97 70 -3 92 -8 155 -40z"/>
<path d="M2288 6148 c-5 -13 312 -1224 326 -1246 5 -8 43 -12 116 -12 94 0
111 2 119 18 5 9 45 186 90 392 45 206 84 382 87 390 10 25 19 -6 110 -400 48
-206 92 -381 97 -387 14 -19 230 -18 246 0 12 15 301 1199 301 1235 0 22 -2
22 -119 22 -66 0 -122 -4 -125 -9 -3 -5 -42 -172 -87 -372 -45 -200 -85 -368
-89 -372 -4 -5 -50 162 -101 370 l-94 378 -127 3 -127 3 -11 -38 c-6 -21 -44
-189 -85 -373 -42 -184 -79 -338 -83 -342 -5 -5 -48 159 -96 364 -48 205 -90
376 -92 380 -9 14 -251 10 -256 -4z"/>
<path d="M5157 6153 c-10 -9 -8 -1235 1 -1251 7 -10 101 -12 438 -10 l429 3 0
105 0 105 -307 3 -308 2 0 509 c0 280 -3 516 -6 525 -5 13 -26 16 -123 16 -65
0 -121 -3 -124 -7z"/>
<path d="M6197 6153 c-10 -9 -8 -1235 1 -1251 7 -10 101 -12 438 -10 l429 3 0
105 0 105 -307 3 -308 2 0 155 0 155 199 0 c158 0 201 3 210 14 8 9 11 47 9
107 l-3 94 -207 3 -208 2 0 150 0 150 298 0 c163 0 303 4 310 8 9 6 12 35 10
108 l-3 99 -431 3 c-237 1 -434 -1 -437 -5z"/>
<path d="M7205 6150 c-4 -6 68 -139 160 -295 91 -157 171 -296 177 -310 10
-22 -6 -53 -162 -320 -95 -163 -173 -304 -174 -313 -1 -15 13 -17 129 -17
l129 0 121 212 c66 117 125 211 130 209 6 -1 57 -86 115 -188 153 -269 118
-238 265 -238 116 0 125 1 125 19 0 10 -77 151 -172 312 -94 162 -171 299
-171 305 0 6 77 144 171 306 95 162 172 302 172 311 0 15 -14 17 -124 17 -98
0 -126 -3 -136 -15 -7 -8 -63 -104 -124 -212 -61 -108 -116 -198 -121 -199 -6
-2 -53 74 -105 168 -52 93 -107 190 -122 214 l-28 44 -124 0 c-74 0 -127 -4
-131 -10z"/>
<path d="M2570 4505 c0 -23 3 -24 78 -27 l77 -3 3 -237 2 -238 30 0 30 0 2
238 3 237 78 3 c74 3 77 4 77 27 l0 25 -190 0 -190 0 0 -25z"/>
<path d="M3390 4265 l0 -265 177 2 177 3 0 25 0 25 -142 3 -142 3 0 94 0 95
125 0 125 0 0 30 0 30 -125 0 -125 0 0 85 0 85 143 0 c143 0 144 0 142 23 -2
22 -3 22 -178 25 l-177 2 0 -265z"/>
<path d="M4210 4265 l0 -265 170 0 170 0 0 30 0 30 -135 0 -135 0 0 235 0 235
-35 0 -35 0 0 -265z"/>
<path d="M4990 4265 l0 -265 177 2 177 3 0 25 0 25 -142 3 -142 3 0 94 0 95
125 0 125 0 0 30 0 30 -125 0 -125 0 0 85 0 85 143 0 c143 0 144 0 142 23 -2
22 -3 22 -178 25 l-177 2 0 -265z"/>
<path d="M5837 4492 l-37 -38 0 -190 0 -190 38 -37 38 -37 125 0 125 0 33 34
c21 22 31 40 28 51 -11 28 -37 29 -66 1 -25 -24 -33 -26 -122 -26 -87 0 -97 2
-117 23 -21 22 -22 33 -22 184 0 147 2 162 21 187 19 25 25 26 113 26 89 0 93
-1 121 -30 l29 -30 23 22 23 22 -32 33 -32 33 -126 0 -126 0 -37 -38z"/>
<path d="M6667 4492 l-37 -38 0 -190 0 -190 38 -37 38 -37 125 0 125 0 37 38
37 38 0 195 0 195 -33 32 -33 32 -130 0 -130 0 -37 -38z m280 -34 c22 -21 23
-27 23 -191 0 -215 5 -207 -133 -207 -90 0 -103 2 -124 22 -22 21 -23 27 -23
183 0 149 2 164 21 189 20 25 25 26 117 26 85 0 98 -3 119 -22z"/>
<path d="M7520 4265 l0 -265 35 0 35 0 2 212 3 212 84 -213 c72 -180 88 -211
104 -209 15 2 37 48 99 203 44 110 84 204 89 210 5 5 9 -74 9 -203 l0 -212 30
0 30 0 0 266 0 265 -46 -3 -47 -3 -82 -210 -83 -210 -14 35 c-8 19 -46 115
-84 212 l-69 178 -47 0 -48 0 0 -265z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
